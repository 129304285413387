<template>
  <div class="my-find">
    <div class="container">
      <textarea v-model="content" placeholder="说点什么把.."></textarea>

      <p>上传规则： 上传内容必填，图片或视频只能选择一种，图片最多上传9张，视频文件大小不超过50MB</p>

      <div class="ft-plant-upload-button" v-if="visibleImage">
        <mt-button class="font-28" type="default" @click="img_uploadFile">上传图片</mt-button>
        <input
          type="file"
          accept="image/*"
          ref="imgfile"
          @change="img_uploadFile_change"
          style="display:none"
        />
      </div>

      <div class="img-box" v-if="images.length > 0">
        <div class="img" v-for="(item, index) in images" :key="index">
          <img :src="item" />
          <i class="jym-close" @click="del(index)"></i>
        </div>
      </div>

      <div class="ft-plant-upload-button" v-if="visibleVideo">
        <mt-button
          style="margin-top: .2rem"
          class="font-28"
          type="default"
          @click="zh_uploadFile"
        >上传视频</mt-button>
        <input
          type="file"
          accept="video/*"
          ref="evfile"
          @change="zh_uploadFile_change"
          style="display:none"
        />
      </div>

      <div class="video-box" v-if="videoSrc">
        <i class="jym-close" @click="videoSrc = ''"></i>
        <video class="video" :src="videoSrc" controls></video>
      </div>

      <div class="btn-bottom">
        <button @click="submit" class="btn-black">发布</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Uploader } from "vant";
Vue.use(Uploader);
import { Indicator } from "mint-ui";
import { VUE_APP_API_URL } from "@utils";
import { publish, getQiniuToken } from "@api/user";
import * as Qiniu from "qiniu-js";

export default {
  data() {
    return {
      token: {},
      content: "",
      type: "",
      images: [],
      media: [],
      headers: {
        "Authori-zation": "Bearer " + this.$store.state.app.token,
      },
      url: `${VUE_APP_API_URL}/upload/image`,
      uploader: null,
      uploadVideoList: [], // 选中的上传视频列表
      videoSrc: "", // 选中的视频的src，用来显示视频
      visibleImage: true,
      visibleVideo: true,
    };
  },
  watch: {
    images(val) {
      if (val.length > 0) {
        this.visibleVideo = false;
        this.visibleImage = true;
      } else {
        this.visibleVideo = true;
        this.visibleImage = true;
        this.media = [];
      }
      console.log(this.media);
    },
    videoSrc(val) {
      if (val) {
        this.visibleVideo = true;
        this.visibleImage = false;
      } else {
        this.visibleVideo = true;
        this.visibleImage = true;
        this.media = [];
      }
      console.log(this.media);
    },
  },
  created() {},
  methods: {
    //选择上传图片
    img_uploadFile() {
      this.$refs.imgfile.click();
    },
    //选择图片后触发的事件
    img_uploadFile_change(imgfile) {
      let that = this;
      Indicator.open("图片上传中...");
      //后端获取token
      getQiniuToken().then((res) => {
        var uptoken = res.data.token;
        var file = imgfile.target.files[0]; //Blob 对象，上传的文件
        var key = 'community/'+new Date().getTime()+file.name; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

        let config = {
          useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          region: Qiniu.region.z0, // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域 
        };

        let putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: "", //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        var observable = Qiniu.upload(file, key, uptoken, putExtra, config);
        observable.subscribe({
          next: () => {
            // 主要用来展示进度
            // console.log(result);
          },
          error: () => {
            // 失败报错信息
            // console.log(errResult);
          },
          complete: (result) => {
            let source = "";
            // 接收成功后返回的信息
            source = res.data.domain + result.key;
            that.images.push(source);
            that.media = that.images.join(",");
            that.type = 1;
            that.visibleVideo = false;
            that.visibleImage = true;
            Indicator.close();
          },
        });
      });
    },
    //选择上传文件
    zh_uploadFile() {
      this.$refs.evfile.click();
    },
    //选择文件后触发的事件
    zh_uploadFile_change(evfile) {
      let that = this;
      Indicator.open("视频上传中...");
      //后端获取token
      getQiniuToken().then((res) => {
        var uptoken = res.data.token;
        var file = evfile.target.files[0]; //Blob 对象，上传的文件
        var key = 'community/'+ new Date().getTime() +file.name; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

        let config = {
          useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          region: Qiniu.region.z0, // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
        };

        let putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: "", //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        var observable = Qiniu.upload(file, key, uptoken, putExtra, config);
        observable.subscribe({
          next: () => {
            // 主要用来展示进度
            // console.log(result);
          },
          error: () => {
            // 失败报错信息
            // console.log(errResult);
          },
          complete: (result) => {
            // 接收成功后返回的信息
            that.media = res.data.domain + result.key;
            that.type = 2;
            that.videoSrc = that.media;
            that.visibleVideo = true;
            that.visibleImage = false;
            Indicator.close();
          },
        });
      });
    },

    // 图片删除
    del(index) {
      let that = this;
      that.images.splice(index, 1);
      that.media = that.images.join(',');
    },

    // 提交
    submit() {
      let that = this;
      if (!that.content) {
        that.$dialog.error("请输入内容！");
        return false;
      }

      let params = {
        content: that.content,
        type: that.type,
        media: that.media,
      };
      publish(params)
        .then((res) => {
          that.$dialog.success(res.msg);
          that.$router.push({
            path: "/user",
          });
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-find {
  padding: 0.2rem 0;
  .container {
    padding: 0.2rem 0.3rem;
    textarea {
      width: 100%;
      height: 3rem;
      background: white;
      font-size: 0.26rem;
      padding: 0.2rem;
      margin-bottom: 0.2rem;
    }
    .img-box {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      margin: 0.3rem -0.1rem 0;
      .img {
        width: 2rem;
        height: 2rem;
        margin: 0.1rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        i {
          position: absolute;
          top: 0;
          right: 0;
          background: red;
          color: white;
          width: 0.4rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.42rem;
          text-align: center;
          z-index: 999;
        }
      }
    }
    p {
      line-height: 0.4rem;
      margin: 0.3rem 0 0.1rem;
      font-size: 0.26rem;
    }
    .video-box {
      margin-top: 0.3rem;
      position: relative;
      i {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        width: 0.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.42rem;
        text-align: center;
        z-index: 999;
      }
      .video {
        width: 100%;
        height: 3.6rem;
      }
    }
    .upload {
      background: white;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      text-align: center;
      i {
        color: #dcdee0;
        font-size: 24px;
        line-height: 80px;
      }
      .btn-primary {
        background-color: #16c2c2;
        color: #fff;
        padding: 0.3rem;
        height: 4rem;
        width: 5rem;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .g-core-image-upload-form {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>